import { BaseEntity } from '../core/models/base-entity';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CustomerFilter } from 'src/app/models/filters/customer-filter';
import { SelectItem } from 'src/app/core/models/responses/select-item';
import { AccountFilter } from 'src/app/models/filters/account-filter';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ParityFilter } from 'src/app/models/filters/parity-filter';
import { Parity } from 'src/app/models/exchange/parity';
import { BranchFilter } from 'src/app/models/filters/branch-filter';
import { CaseFilter } from 'src/app/models/filters/case-filter';
import { BaseListResponse } from '../core/models/responses/base-list-response';
import { DictionaryFilter } from 'src/app/models/filters/dictionary-filter';
import { WorkshopFilter } from 'src/app/models/filters/workshop-filter';
import { ApplicationUserFilter } from '../models/filters/application-user-filter';
import { CaseSelectItem } from '../models/case/case-select-list-item';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  countingId(countingId: any) {
    throw new Error("Method not implemented.");
  }
  constructor(
    protected _http: HttpClient,
  ) {}

  apiUrl = environment.urlAddress + "/data/";
  getBranch(filter: BranchFilter): Observable<BaseListResponse<SelectItem>> {
    return this._http.post<BaseListResponse<SelectItem>>(this.apiUrl + 'branch', filter);
  }      
  getCase(filter: CaseFilter): Observable<BaseListResponse<CaseSelectItem>> {
    return this._http.post<BaseListResponse<CaseSelectItem>>(this.apiUrl + 'case', filter);
  }      
  getCustomer(filter: CustomerFilter): Observable<BaseListResponse<SelectItem>> {
    return this._http.post<BaseListResponse<SelectItem>>(this.apiUrl + 'customer', filter);
  }      
  getAccount(filter: AccountFilter): Observable<BaseListResponse<SelectItem>> {
    return this._http.post<BaseListResponse<SelectItem>>(this.apiUrl + 'account', filter);
  } 
  getCurrency(): Observable<BaseListResponse<SelectItem>> {
    return this._http.get<BaseListResponse<SelectItem>>(this.apiUrl + 'currency');
  } 
  getParity(filter: ParityFilter): Observable<BaseListResponse<Parity>> {
      return this._http.post<BaseListResponse<Parity>>(this.apiUrl + 'parity', filter);
  } 

  getProductGroup(): Observable<BaseListResponse<SelectItem>>{
    return this._http.get<BaseListResponse<SelectItem>>(this.apiUrl + 'productGroup');
  } 
  
  getDictionary(filter: DictionaryFilter): Observable<BaseListResponse<SelectItem>> {
    return this._http.post<BaseListResponse<SelectItem>>(this.apiUrl + 'dictionary', filter);
  }  
  getWorkshop(filter: WorkshopFilter): Observable<BaseListResponse<SelectItem>> {
    return this._http.post<BaseListResponse<SelectItem>>(this.apiUrl + 'workshop', filter);
  }
  getApplicationUser(filter: ApplicationUserFilter): Observable<BaseListResponse<SelectItem>> {
    return this._http.post<BaseListResponse<SelectItem>>(this.apiUrl + 'application-user', filter);
  }  
  getDraftOrders() : Observable<BaseListResponse<SelectItem>> {
    return this._http.get<BaseListResponse<SelectItem>>(this.apiUrl + 'draft-orders');
  }

  

  errorHandler(error:any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
}